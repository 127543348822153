import siteCommon from 'site/site-common'
import gsap, { Power4 } from 'gsap'
let oneId = {
    pid: null,
    oneIdObject: null,
    guest: null,
    becomeMemberIsOpen: false,
    cssUrlFinal: null,
    init: function() {

        // EMAIL
        $('.member-signup-form-hook .field-submit').on('click',oneId.emailSignup)
        $('.members-signup-hook').on('click',oneId.emailSignupPopup)


        // LOGIN LISTENERS
        $('.hook-oneid-login-clicker').on('click', oneId.login)
        $('.hook-oneid-logout-clicker').on('click', oneId.logout)
        $('.hook-oneid-login').hide()
        $('.hook-oneid-logout').hide()

        // BECOME MEMBER POPUP
        $('.hook-oneid-become-member-click').on('click', oneId.becomeMemberOpen);
        $('.hook-oneid-become-member-close').on('click', oneId.becomeMemberClose);
        $('.create-account-hook').on('click',oneId.launchRegistration)
        $('.manage-account-hook').on('click',oneId.launchProfile)
        $(document).keyup(function(e) {
            if (oneId.becomeMemberIsOpen) {
                if (e.keyCode === 27) { // escape key
                    oneId.becomeMemberClose();
                }
            }
        })


        // CONTEST HOOK
        // basically it is the members-signup or login, except it stores an ID that upon completion we later send to the database
        $('.contest-id-hook').on('click',oneId.contestSignup)

        //ONE ID INIT
        let scriptURL = null
        let responderUrl = null
        let debug = false
        const loc = window.location.toString()
        responderUrl = site_url + "/responder.html"
        //responderUrl = '/responder.html' // note this is not an actual file but is configured in craft cms - making it not an actual file fixes artifacturl issues with the file
        if (
            loc.indexOf('preview.fslcdn.net') > -1 ||
            loc.indexOf('searchlight-studio-2023.dev.realpie.com') > -1 ||
            loc.indexOf('localhost') > -1 ||
            loc.indexOf('ddev.site') > -1 ||
            loc.indexOf('searchlight-prod-1103379d.preview.craft.cloud') > -1
        ) {
            //QA
            //scriptURL = "https://qa.cdn.registerdisney.go.com/v4/OneID.js"
            scriptURL = "https://stg.cdn.registerdisney.go.com/v4/OneID.js"

            //scriptURL = "https://cdn.registerdisney.go.com/v4/responder.js"
            //responderUrl = "https://qa.cdn.registerdisney.go.com/v4/responder.js"
            debug = true
        } else {
            //PROD
            scriptURL = "https://cdn.registerdisney.go.com/v4/OneID.js"
            //responderUrl = "https://cdn.registerdisney.go.com/v4/responder.js"
        }
        //site_url = "https://searchlightpictures.com"
        let script = document.createElement('script')
        script.onload = function () {
            let cssUrl = site_url
            // cssUrl = 'https://www.searchlightpictures.com' //problems with dev site css, use live
            //oneId.cssUrlFinal = cssUrl + "/assets-site/oneid/oneidv5.css?i=11"
            oneId.oneIdObject = OneID.get({
                initialize: false,
                clientId: 'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB',
                //cssOverride: oneId.cssUrlFinal,
                langPref: "en-US",
                responderPage: responderUrl,
                debug: debug,
                optionalConfigs: {
                    displayOptions: {
                        darkMode: false
                    }
                },
            })
            oneId.oneIdObject.on('close', () => {

            })

            oneId.oneIdObject.on('opt-in', (optins) => {

            });

            oneId.oneIdObject.on('create', (optins) => {
                //window.location.reload();
                let currentUrl = window.location.href;
                let newtype = 1
                if (oneId.pid) {
                    newtype = 2
                }
                if (currentUrl.indexOf('?') > -1) {
                    currentUrl += '&new='+newtype;
                } else {
                    currentUrl += '?new='+newtype;
                }
                window.location.href = currentUrl;
            });


            oneId.oneIdObject.on('login', (data) => {
                console.info('login')
                if (data) {
                    oneId.guest = data
                    oneId.showLogin()
                } else {
                    oneId.showLogout()
                }

                if (oneId.pid) {
                    oneId.contestOnLogin()
                }
            });

            oneId.oneIdObject.on('logout', (data) => {
                oneId.logoutKillCookiesAndShowLogout()
            });

            try {
                oneId.oneIdObject.init().then((data) => {
                    oneId.postInit(data);
                })
            } catch (error) {
                console.error('OneID.get error');
                console.error(error);
            }
        }
        script.onerror = function (e) {
            console.error('onerror')
            console.error(e)
        }

        script.setAttribute("type", "text/javascript")
        script.setAttribute("id", "disneyid-script")
        //alert(scriptURL)
        script.setAttribute("src", scriptURL)
        document.head.appendChild(script);


    },
    getCookie: function (name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    },

    emailSignup: async function () {
        const id = $(this).attr('data-hook-id')
        const sel = '#member-signup-form-hook-'+id
        const email = $(sel + ' .field-email').val()
        if (!siteCommon.isValidEmail(email)) {
            $(sel + ' .email-results').html('<p>Invalid Email</p>')
        } else {
            $(sel + ' .email-results').html('<p>Please wait...</p>')
            try {
                // get inline newsletters and hide/show as needed
                let res = await oneId.oneIdObject.getInlineNewsletters('searchlightpictures')


                let data = res.data
                data.email = email
                data.marketing.forEach(item => item.subscribed = true)
                data.legal.forEach(item => item.accepted = true)

                let res2 = await oneId.oneIdObject.setInlineNewsletters(data)

                $(sel + ' .email-results').html("<p>You're in! Thank you.</p>")
            } catch (e) {
                $(sel + ' .email-results').html('<p>' + e + '</p>')
            }
        }
    },

    // for now, mostly launches email popup
    postInit: async function(data) {
        //let isGuest = await oneId.getGuest()
        let isGuest = false
        if (data.loggedIn) {
            isGuest = true
            oneId.guest = data
            oneId.showLogin()
        } else {
            // show logout
            oneId.showLogout()

            // you can do email popup if not logged in
            let emailAutoPopup = oneId.getCookie('emailAutoPopup')
            if (!emailAutoPopup) {
                setTimeout(function() {
                    oneId.postInit2()
                },10*1000)

            }
        }
    },

    // for now, mostly launches email popup
    postInit2:function() {
        if (!window.overlayIsOpen) {
            //oneId.emailSignupPopup()
            oneId.becomeMemberOpen()

            // 60 seconds x 60 minutes x 24 hours x 7 days
            document.cookie = `emailAutoPopup=1; path=/; max-age=${60 * 60 * 24 * 7};`;
            window.autoPopupBlocked = false
        } else {
            window.autoPopupBlocked = true
        }
    },

    emailSignupPopup( ) {
        oneId.oneIdObject.launchNewsletters("searchlightpictures")
    },

    logout() {
        if (oneId.guest) {
            oneId.oneIdObject.logout().then(function(data){
                oneId.logoutKillCookiesAndShowLogout()
                return true
            })
            .catch(function(error){
                console.error('oneIdObject.logout error')
                console.error(error)
                oneId.logoutKillCookiesAndShowLogout()
                return false
            })
            //oneId.oneIdObject.forceTokenRefresh()
        }
        //oneId.showLogout()
    },

    logoutKillCookiesAndShowLogout: async function() {
        const cookiesToDelete = [
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-ac',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-api',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-idn',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-token',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-ts',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-QA.ac',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-QA.api',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-QA.idn',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-QA.token',
            'STUDIO-SEARCHLIGHTPICTURESWEBSIT.WEB-QA.ts'
        ];
        for (const cookieName of cookiesToDelete) {
            await oneId.deleteCookie(cookieName);
        }

        oneId.oneIdObject = null;
        //oneId.showLogout();
        window.location.reload();
    },

    login: async function() {
        let loggedIn = await oneId.oneIdObject.getLoggedInStatus()
        //let loggedIn = oneId.oneIdObject.loggedIn
        if (!loggedIn) {
            //trying this to overcome some bugs ive seen

            //oneId.oneIdObject.logout()
            oneId.oneIdObject.launchLogin(
                {
                    cssOverride: oneId.cssUrlFinal,
                    optionalConfigs: {
                        displayOptions: {
                            darkMode: false
                        }
                    }
                }
            ).then(function(data){
                oneId.getGuest()
            })
            .catch(function(error){
                console.error('launchLogin error')
                console.error(error)
            })
        }
    },

    showLogin() {
        $('.hook-oneid-init').hide()
        $('.hook-oneid-postinit').show()
        $('.hook-oneid-login').hide()
        $('.hook-oneid-logout').show()
        $('body').removeClass('blr');
        $('#become-member-popup').hide()

        const urlParams = new URLSearchParams(window.location.search)
        const newtypeValue = String(urlParams.get('new'))
        if (newtypeValue === '2') {
            let pid = $('.contest-id-hook').data('contest-id');
            if (pid) {
                oneId.pid = pid
                let swid = oneId?.guest?.guest?.profile?.swid
                if (swid) {
                    oneId.contestSendSwid(swid)
                }
            }
        }
    },
    showLogout() {
        oneId.guest = null
        $('.hook-oneid-init').hide()
        $('.hook-oneid-postinit').show()
        $('.hook-oneid-login').show()
        $('.hook-oneid-logout').hide()

        if (site_segment1 === 'members') {
            oneId.blurMemberPortal()
        } else {
            $('body').removeClass('blr');
        }
    },

    blurMemberPortal() {
        oneId.becomeMemberOpen()
        $('body').addClass('blr');
    },

    getGuest: async function() {
        let loggedIn = await oneId.oneIdObject.getLoggedInStatus()
        //let loggedIn = oneId.oneIdObject.loggedIn
        if (loggedIn) {
            let ret = await oneId.oneIdObject.getGuest(true)
                .then(function(data){
                    oneId.guest = data
                    oneId.showLogin()
                    return true
                })
                .catch(function(error){
                    console.error('getGuest error')
                    console.error(error)
                    oneId.showLogout()
                    return false
                })
            return ret
        } else {
            console.error('getGuest: not logged in')
            oneId.showLogout()
            return false
        }
    },

    becomeMemberOpen() {
        if (oneId.becomeMemberIsOpen === false) {
            gsap.fromTo($('#become-member-popup'), 0.5,
                {
                    opacity:0,
                },
                {
                    opacity: 1,
                    delay: 0,
                    ease: Power4.easeInOut,
                    onComplete: oneId.becomeMemberOpen2
                }
            )
            $('#become-member-popup').show()
        }
    },
    becomeMemberOpen2() {
        oneId.becomeMemberIsOpen = true
    },
    becomeMemberClose() {
        let isBlurred = $('body').hasClass('blr');
        if (oneId.becomeMemberIsOpen && !isBlurred) {
            gsap.fromTo($('#become-member-popup' ), 0.5,
                {
                    opacity:1,
                },
                {
                    opacity:0,
                    delay: 0,
                    ease: Power4.easeInOut,
                    onComplete:oneId.becomeMemberClose2
                }
            )
        }
    },
    becomeMemberClose2:function() {
        $('#become-member-popup').hide()
        oneId.becomeMemberIsOpen = false
    },


    launchRegistration() {
        oneId.oneIdObject.launchRegistration()
        // this API returns a Promise object (thenable)
        // the first function passed to then() is success(data)
        .then(function(data){

        })
        // the second function is an error handler (optional)
        .catch(function(error){
            console.error(error);
        });
    },
    launchProfile() {
        oneId.oneIdObject.launchProfile()
            // this API returns a Promise object (thenable)
            // the first function passed to then() is success(data)
            .then(function(data){
            })
            // the second function is an error handler (optional)
            .catch(function(error){
                console.error(error);
            });
    },

    deleteCookie: async function (name) {

        var domain = window.location.hostname;
        var path = window.location.pathname;

        // Possible paths and domains for deletion
        var paths = ['/', path];
        var domains = [domain, '.' + domain];

        // Delete from all combinations of paths and domains
        for (var i = 0; i < paths.length; i++) {
            for (var j = 0; j < domains.length; j++) {
                document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=' + paths[i] + '; domain=' + domains[j] + ';';
            }
        }

        // Delete without domain and path as a fallback
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';

        // Additional check using cookieStore if available
        if (typeof cookieStore !== 'undefined' && typeof cookieStore.delete === 'function') {
            try {
                const deleted = await cookieStore.delete(name);
                return deleted;
            } catch (error) {
                console.error(`Failed to delete cookie using cookieStore: ${error}`);
            }
        }
        return false;
    },

    contestSignup: async function() {
        $('#button-holder-error').html('');
        let swid = null
        let pid = $(this).attr('data-contest-id')
        oneId.pid = pid
        let loggedIn = await oneId.oneIdObject.getLoggedInStatus()
        if (loggedIn) {
            let guest = await oneId.oneIdObject.getGuest()
            if (guest?.profile?.swid) {
                swid = guest?.profile?.swid
            }
        }

        if (swid) {
            oneId.contestSendSwid(swid)
        } else {
            // in the on login event, we'll store the contest ID there.  if contest ID is stored, then we'll send the swid
            oneId.login()
        }
    },

    contestOnLogin: function() {
        let swid = null
        if (oneId.guest?.profile?.swid) {
            swid = oneId.guest?.profile?.swid
        }

        if (swid) {
            oneId.contestSendSwid(swid)
        }
    },

    contestSendSwid: async function(swid) {
        // send swid to back end
        const apiUrl = `/actions/contest-submit`
        const csrf = $('#page-csrf').val();
        let session = await fetch('/actions/users/session-info', {
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => response.json());

        $.ajax({
            url: apiUrl,
            type: 'POST',
            dataType: 'json',
            crossDomain: true,
            data: {
                pid: oneId.pid,
                swid: swid
            },
            headers: {
                'X-CSRF-Token': session?.csrfTokenValue,
            },
            success: function (response, textStatus) {
                if (response.success) {
                    oneId.pid = null
                    $('#button-holder-final-message').show();
                    $('#button-holder-main').html('');
                } else {
                    $('#button-holder-error').html('<p>Error.  Please try again later.</p>');
                }
            },
            error: function (jqXHR, textStatus) {
                $('#button-holder-error').html('<p>Error.  Please try again later.</p>');
            }
        });

        oneId.pid = null
    }

}
export default oneId